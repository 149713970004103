<template>
    <div class="box my-account">
		<div style="text-align: left; background-color:#D81E01; padding: 15px; margin-bottom: 20px; color:#FFFFFF; border-radius: 5px; line-height: 30px;">
			<h3>支付转账账户</h3>
		    开户名称：{{ this.accountDetail.bank_account_name }}<br />
		    开户银行：{{ this.accountDetail.bank_name }}<br />
		    账号：{{ this.accountDetail.bank_account_no }}
		</div>
        <div class="buyCouponPay">
            <el-form :model="shopCouponsData" label-width="120px" :rules="rules" ref="shopCouponsData">
                <el-form-item label="订单号：">
                    {{this.orderDetail.order_no}}
                </el-form-item>
                <el-form-item label="应付金额：">
                    <strong style="font-size: 20px; color: #D81E01;">￥{{this.orderDetail.pay_money}}</strong>
                </el-form-item>
                <el-form-item label="支付凭证：" prop="pay_prove">
                    <el-upload
                        :action="uploadActionUrl"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :limit="1"
                        :on-success="handleAvatarSuccess"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog append-to-body :visible.sync="showImg">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item><br /><br />
                <el-form-item label="">
                    <el-button type="primary" @click="subBuyData('shopCouponsData')">确定上传</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>

    import Config from "@/utils/config"
	import {
		apiOrderDetail,
		getPayTickets,
		sendPayTickets
	} from '@/api/order/order';
    import {voucherOrderPay} from '@/api/member/member'
    export default {
        name: "",
        data() {
            return {
				orderDetail:{},
				accountDetail:{},
                dialogImageUrl: "",
                showImg: false,
                uploadActionUrl: Config.baseUrl + "/api/upload/headimg",// 图片上传地址
                shopCouponsData: {
                    order_no: "",
                    pay_prove: [],// 支付凭证
                    user_remark: ""// 用户备注
                },
                fileList: [],
                query: {},
                rules: {
                    pay_prove: [
                        { required: true, message: "请上传支付凭证", trigger: "blur" }
                    ]
                }
            }
        },
        props: {},
        mixins: [],
        watch: {},
        computed: {},
        created() {
            this.orderId = this.$route.query.order_id;
			this.getOrderDetail();
			this.handleGetPayTickets();
        },
        methods: {
			getOrderDetail() {
				apiOrderDetail({
						order_id: this.orderId
					})
					.then(res => {
						if (res.code >= 0) {
							this.orderDetail = res.data;
							if(this.orderDetail.pay.pay_imgs && this.orderDetail.pay.pay_imgs.length > 0)
							{
								var k=0;
								this.shopCouponsData.pay_prove = [];
								this.orderDetail.pay.pay_imgs.map(item => {
									this.fileList.push({name:item,url: item});
									this.shopCouponsData.pay_prove.push(item);
								});
							}
							this.loading = false;
						} else {
							this.$message({
								message: '未获取到订单信息',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/order_list'
									});
								}
							});
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					});
			},
			//获取支付账户
			handleGetPayTickets(){
				getPayTickets({order_id: this.orderId}).then(ret => {
					if(ret.code == 0)
						this.accountDetail = ret.data;
				}).catch(err => {
					console.log(err);
				});
			},
            subBuyData(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                      this.handleSendPayTickets()
                    } else {
                        return false
                    }
                })
            },
            // 提交支付凭证
            handleSendPayTickets() {
                let par = {
                    order_id: this.orderId,
                    pay_imgs: this.shopCouponsData.pay_prove,
                    rnt: Math.random()
                }
                sendPayTickets(par).then(res => {
                    if(res.data.code<0){
                       this.$message.error(res.data.data)
                    }else{
                        this.$router.push({
                            path: '/member/order_list'
                        }) 
                    }
                    
                })
            },
            handleRemove(file, fileList) {
                let list = []
                fileList.map(item => {
                    if (item.response) {
                        list.push(item.response.data.pic_path)
                    } else {
                        list.push(item.url)
                    }
                })
                this.shopCouponsData.pay_prove = list
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || "image/png"
                const isLt2M = file.size / 1024 / 1024 < 10

                if (!isJPG) {
                    this.$message.error("上传图片只能是 JPG ,png格式!")
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 10MB!")
                }
                return isJPG && isLt2M
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件!`)
            },
            handleAvatarSuccess(res, file) {
                if(res.code<0){
                    this.$message.warning(res.message)
                }
                let data = res.data
                this.shopCouponsData.pay_prove.push(data.pic_path)
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url
                this.showImg = true
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 50px 20px;
    font-size: 16px;
  }

  .buyCouponPay {
    ::v-deep .el-upload--picture-card {
      width: 100px;
      height: 100px;
      line-height: 98px;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item{
      width: 100px;
      height: 100px;
    }
  }

</style>
